<template>
  <div class="templateDetails">
    <el-button @click="templateUseClick"
               class="button-box"> <span v-if="questionList.length===0">套用模板</span><span v-else>切换模板</span></el-button>
    <div v-if="currentExamineType===0">
      <div style="font-weight: bold">竹香学校教师年度考核业务素养评分细则</div>
      <hr>
      <div>考核对象：<span style="margin-left: 80px">评分时间：</span></div>
      <el-table :data="questionList"
                border>
        <el-table-column label="领域"
                         width="40px"
                         prop="ly"></el-table-column>
        <el-table-column label="项目"
                         width="120px"
                         prop="xm"></el-table-column>
        <el-table-column label="评分细则"
                         prop="xz"></el-table-column>
        <el-table-column label="评分说明"
                         prop="sm"></el-table-column>
        <el-table-column label="分值"
                         width="50px"
                         align="center"
                         prop="maxscore"></el-table-column>
        <el-table-column label="自评"
                         width="50px"
                         align="center"
                         prop=""></el-table-column>
        <el-table-column label="得分"
                         width="50px"
                         align="center"
                         prop=""></el-table-column>
        <el-table-column label="签名"
                         width="50px"
                         align="center"
                         prop=""></el-table-column>
        <el-table-column label="部门"
                         width="120px"
                         align="center"
                         prop="groupname"></el-table-column>
      </el-table>
    </div>
    <div v-else>
      <div v-for="(template,index) in questionList"
           :key="index">
        <div class="option-box">
          {{index+1}}、{{template.title}}</div>
        <el-radio-group v-model="template.option_id"
                        class="option-box">
          <el-radio v-for="optic in template.optionList"
                    :key="optic.id"
                    :label="optic.level">{{optic.content}}</el-radio>
        </el-radio-group>
        <div>
          <el-button size="mini"
                     @click="insertOption(index)"
                     type="success">插入题目</el-button>
          <el-button size="mini"
                     type="primary"
                     @click="modifyOption(template,index)">编 辑</el-button>
          <el-button size="mini"
                     @click="moveDown(index)"
                     type="primary">下 移</el-button>
          <el-button size="mini"
                     @click="moveUp(index)"
                     type="primary">上 移</el-button>
          <el-button size="mini"
                     type="danger"
                     @click="deteleOption(index)">删 除</el-button>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <el-button @click="$router.push({name:'annualExamine'})">取 消</el-button>
      <el-button type="success"
                 @click="questionConfirm">发 布</el-button>
    </div>
    <el-dialog :visible.sync="addOptionVisible"
               :close-on-click-modal='false'
               :title="addOptionForm.id?'修改题目':'添加题目'"
               width="600px">
      <el-form :model="addOptionForm"
               class="addOptionForm-box"
               label-width="50px"
               ref="addOptionForm">
        <el-form-item prop="title"
                      label="题目">
          <el-input class="form-item-input-box"
                    v-model="addOptionForm.title"></el-input>
        </el-form-item>
        <el-form-item v-for="(option, index) in addOptionForm.optionList"
                      :prop="'optionList.' + index + '.level'"
                      :label="'选项'+(index+1)"
                      :key="index">
          <div class="option-item-box">
            <!-- <div>
              <span>序号：</span>
              <el-input v-model="option.sort_index"
                style="width:50px;margin-right:10px"></el-input>
            </div> -->
            <div>
              <span>等级：</span>
              <el-input v-model="option.level"
                        style="width:50px;margin-right:10px"></el-input>
            </div>
            <div>
              <span>内容：</span>
              <el-input v-model="option.content"
                        style="width:170px"></el-input>
            </div>
          </div>
          <span>类型：</span>
          <el-radio-group v-model="option.type">
            <el-radio :label="0">正常</el-radio>
            <el-radio :label="1">一票否决</el-radio>
          </el-radio-group>
          <el-button @click.prevent="removeOption(option)"
                     type="danger">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="addOption(addOptionForm.optionList)"
                     type="primary">新增选项</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addOptionConfirm"
                   type="primary">确 定</el-button>
        <el-button @click="addOptionVisible=false">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="publishExamineVisible"
               width="800px"
               title="调用该模板">
      <div class="template-box">
        <el-tabs v-model="currentTemplate"
                 type="card"
                 @tab-click="templateUseClick">
          <el-tab-pane label="模板一"
                       name="business1"
                       v-if="currentExamineType===0"></el-tab-pane>
          <el-tab-pane label="模板一"
                       name="teacher1"
                       v-if="currentExamineType===1"></el-tab-pane>
          <el-tab-pane label="模板一"
                       name="student1"
                       v-if="currentExamineType===2"></el-tab-pane>
        </el-tabs>
        <div v-if="currentExamineType!==0">
          <div v-for="template in templateList"
               :key="template.id">
            <div class="padding-box">
              {{template.sort_index}}、{{template.title}}</div>
            <el-radio-group v-model="template.option_id"
                            class="padding-group">
              <el-radio v-for="optic in template.optionList"
                        :key="optic.id"
                        :label="optic.level">{{optic.content}}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div v-else>
          <div style="font-weight: bold">竹香学校教师年度考核业务素养评分细则</div>
          <hr>
          <div>考核对象：<span style="margin-left: 80px">评分时间：</span></div>
          <el-table :data="templateList"
                    border>
            <el-table-column label="领域"
                             width="40px"
                             prop="ly"></el-table-column>
            <el-table-column label="项目"
                             width="120px"
                             prop="xm"></el-table-column>
            <el-table-column label="评分细则"
                             prop="xz"></el-table-column>
            <el-table-column label="评分说明"
                             prop="sm"></el-table-column>
            <el-table-column label="分值"
                             width="50px"
                             align="center"
                             prop="maxscore"></el-table-column>
            <el-table-column label="自评"
                             width="50px"
                             align="center"
                             prop=""></el-table-column>
            <el-table-column label="得分"
                             width="50px"
                             align="center"
                             prop=""></el-table-column>
            <el-table-column label="签名"
                             width="50px"
                             align="center"
                             prop=""></el-table-column>
            <el-table-column label="部门"
                             width="120px"
                             align="center"
                             prop="groupname"></el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="templateConfirm"
                   type="primary">选择模板</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "templateDetails",
  data() {
    return {
      questionList: [],
      templateList: [],
      currentExamineType: Number(this.$route.query.currentExamineType),
      currentExamineId: Number(this.$route.query.currentExamineId),
      addOptionVisible: false,
      publishExamineVisible: false,
      addOptionForm: {
        currentSort: "",
        title: "",
        id: "",
        optionList: [{ type: "", content: "", sort_index: "", level: "" }],
      },
      currentTemplate: "",
    };
  },
  created() {
    this.loadExamine();
    if (this.currentExamineType === 1) {
      this.currentTemplate = "business1";
    } else if (this.currentExamineType === 2) {
      this.currentTemplate = "teacher1";
    } else {
      this.currentTemplate = "student1";
    }
  },
  watch: {
    questionList: {
      handler(val) {},
      deep: true,
    },
  },
  methods: {
    loadExamine() {
      const that = this;
      let apiUrl = "/base/querySyEvaluation.do";
      let data = { ndid: this.currentExamineId };
      if (this.currentExamineType !== 0) {
        data.wtype = this.currentExamineType;
        apiUrl = "/base/queryQuestionnaire.do";
      }
      this.$post(apiUrl, data)
        .then((res) => {
          if (this.currentExamineType === 0) {
            //记录领域类型
            let columnTitles = [];
            res.data.forEach((item) => {
              //记录同一领域的第一条数据，并赋值rowspan
              if (columnTitles.indexOf(item.ly) === -1) {
                item.rowspan = 0;
                columnTitles.push(item.ly);
              }
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].ly === item.ly) {
                  item.rowspan += 1;
                }
              }
            });
          }
          that.questionList = res.data;
          if (that.questionList.length === 0) {
            that.templateUseClick();
          }
        })
        .catch((err) => {
          that.$message.error(err.message.data);
        });
    },
    questionConfirm() {
      const that = this;
      if (this.questionList.length === 0) {
        this.$message({
          type: "warning",
          message: "发布失败，请完成套用模板",
        });
        return;
      }
      this.$confirm("是否确认发布该问卷", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //教师问卷接口用postJson
          let apiUrl = "/base/insertTeaQuestion.do";
          let data = {};
          if (this.currentExamineType !== 0) {
            let questionList = [];
            this.questionList.forEach((question) => {
              question.ndid = this.currentExamineId;
              questionList.push(question);
            });
            data = questionList;
            this.$postJson(apiUrl, data)
              .then((res) => {
                that.$message({
                  type: "success",
                  message: "操作成功!",
                });
                that.$router.replace({
                  name: "annualExamine",
                  path: "annualExamineManage/annualExamine",
                });
              })
              .catch((err) => {
                that.$message.error(err.message);
              });
          } else if (this.currentExamineType === 0) {
            //业务接口用post
            apiUrl = "/base/copySyEvaluation.do";
            data = { ndid: 1, nowid: this.currentExamineId };
            this.$post(apiUrl, data)
              .then((res) => {
                that.$message({
                  type: "success",
                  message: "操作成功!",
                });
                that.$router.replace({
                  name: "annualExamine",
                  path: "annualExamineManage/annualExamine",
                });
              })
              .catch((err) => {
                that.$message.error(err.message);
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    modifyOption(template, index) {
      this.addOptionVisible = true;
      this.$nextTick(() => {
        this.addOptionForm = JSON.parse(JSON.stringify(template));
        this.addOptionForm.currentSort = index;
      });
    },
    insertOption(index) {
      this.addOptionVisible = true;
      this.$nextTick(() => {
        this.$refs["addOptionForm"].resetFields();
        if (this.addOptionForm.optionList) {
          this.addOptionForm.optionList = [];
        }
        this.addOptionForm.currentSort = index;
        this.addOptionForm.id = "";
      });
    },
    deteleOption(index) {
      this.$confirm("是否删除该题目", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.questionList.splice(index, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    addOptionConfirm() {
      this.$confirm("是否确认进行该操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (!this.addOptionForm.id) {
            this.questionList.splice(
              this.addOptionForm.currentSort,
              0,
              this.addOptionForm
            );
            this.addOptionVisible = false;
          } else {
            const length =
              this.questionList[this.addOptionForm.currentSort].optionList
                .length;
            this.questionList[this.addOptionForm.currentSort].title =
              this.addOptionForm.title;
            for (let i = 0; i < length; i++) {
              this.questionList[this.addOptionForm.currentSort].optionList[i] =
                this.addOptionForm.optionList[i];
            }
            this.addOptionVisible = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    removeOption(item) {
      let index = this.addOptionForm.optionList.indexOf(item);
      if (index !== -1) {
        this.addOptionForm.optionList.splice(index, 1);
      }
    },
    addOption(optionList) {
      this.addOptionForm.optionList.push({
        type: "",
        content: "",
        sort_index: optionList.length + 1,
        level: "",
      });
    },
    moveDown(index) {
      this.questionList.splice(
        index,
        1,
        ...this.questionList.splice(index + 1, 1, this.questionList[index])
      );
    },
    moveUp(index) {
      this.questionList.splice(
        index - 1,
        1,
        ...this.questionList.splice(index, 1, this.questionList[index - 1])
      );
    },
    templateUseClick() {
      const that = this;
      //类型为业务，状态为已完成评价的往年问卷---还没写
      //业务模板
      if (this.currentExamineType === 0) {
        // this.$post("/base/querySyEvaluation.do", { ndid: 1 }) //原模板
        this.$post("/base/querySyEvaluation.do", { ndid: 2 }) 
          .then((res) => {
            let columnTitles = [];
            res.data.forEach((item) => {
              //记录同一领域的第一条数据，并赋值rowspan
              if (columnTitles.indexOf(item.ly) === -1) {
                item.rowspan = 0;
                columnTitles.push(item.ly);
              }
              for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].ly === item.ly) {
                  item.rowspan += 1;
                }
              }
            });
            that.templateList = res.data;
          })
          .catch((err) => {
            that.$message({
              type: "warning",
              message: err.message,
            });
          });
      } else {
        //教师模板
        // let data = { ndid: 2, wtype: 1 }; //原模板
        let data = { ndid: 4, wtype: 1 }; //更换模板
        //学生模板
        if (this.currentExamineType === 2) {
          ///base/queryQuestionnaire.do
          //data = { ndid: 3, wtype: 2 };  //原模板
          data = { ndid: 5, wtype: 2 }; //更换模板
        }
        this.$post("/base/queryQuestionnaire.do", data)
          .then((res) => {
            that.templateList = res.data;
          })
          .catch((err) => {
            that.$message({
              type: "warning",
              message: err.message,
            });
          });
      }
      this.publishExamineVisible = true;
      // this.previewTemplate();
    },
    templateConfirm() {
      this.questionList = this.templateList;
      this.publishExamineVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.footer-box {
  margin-top: 20px;
}
.option-box {
  padding: 10px;
}
.radio-box {
  padding: 15px;
  display: flex;
}
.radio-box > div {
  margin-left: 20px;
}
.form-item-input-box {
  width: 217px;
  padding: 5px 0;
  display: block;
}
.el-form-item__content {
  display: flex;
  justify-content: space-between;
}
.el-form-item__content > button {
  display: block;
  margin-top: 5px;
}
.addOptionForm-box {
  height: 470px;
  overflow-x: auto;
}
.button-box {
  margin-bottom: 20px;
}
.template-box {
  height: 500px;
  overflow-x: auto;
}
.option-item-box {
  display: flex;
}
</style>
